<template>
  <div>
    <header-slot />

    <b-nav card-header pills class="m-0">
      <template v-for="(item, index) in filteredTabs">
        <b-nav-item
          v-if="
            item.route != 'management-reports-comercial' ||
            (item.route == 'management-reports-comercial' && isCeo)
          "
          :key="index"
          :to="{ name: item.route }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          {{ item.name }}
        </b-nav-item>
      </template>
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import { canNavigateDirect } from "@/libs/acl/routeProtectionDirect";
import { getUserData } from "@/auth/utils";
export default {
  data() {
    return {
      tabs: [
        {
          route: "creative-reports-comercial",
          name: "COMMERCIAL",
        },
      ],
      filteredTabs: [],
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  created() {
    this.filteredTabs = this.tabs;
  },
  methods: {},
  beforeRouteEnter(to, from, next) {
    const userData = getUserData();
    // Check if user can navigate directly to the specified route (using user's role and software engineering level)
    if (!canNavigateDirect(to, userData.arrRoles, userData.eng_soft)) {
      return next({ name: "misc-not-authorized" });
    } else {
      return next();
    }
  },
};
</script>

<style>
</style>
